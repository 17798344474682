<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-alert class="p-1" show variant="warning" v-if="inAnalysis"
            ><p>
              <b>Cadastro em análise: </b>Suas informações estão em processamento. Em
              breve você será informado sobre esta análise
            </p></b-alert
          >
          <b-alert class="p-1 border-danger" show variant="danger" v-if="repproved"
            ><p>
              <b>Regularize seu cadastro: </b>
              {{
                $store.state.company.current.ultima_validacao_documentacao
                  .reason_disapproval
              }}.
            </p></b-alert
          >
        </b-col>
        <b-col cols="12">
          <b-row class="pb-2">
            <b-col class="d-flex align-items-start">
              <h4>Dados da Empresa</h4>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <p>
                <b-badge
                  class="border-warning"
                  v-if="inAnalysis"
                  variant="light-warning"
                  pill
                  >Em análise</b-badge
                >
                <b-badge
                  class="border-danger"
                  v-if="repproved"
                  variant="light-danger"
                  pill
                  >Reprovada</b-badge
                >
                <b-badge
                  class="border-success"
                  v-if="approved"
                  variant="light-success"
                  pill
                  >Aprovada</b-badge
                >
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <CompanyDocumentCard :company="currentCompany" />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" v-if="currentCompany.representantes_legais.length">
          <b-row class="pb-2">
            <b-col class="d-flex justify-content-between align-items-center">
              <h4>Representantes legais da empresa</h4>
              <b-button variant="outline-primary" @click="showForm = true" class="d-flex align-items-center">Adicionar assinante <feather-icon icon="PlusIcon" size="18" class="ml-1" /> </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <representative-form v-if="showForm" @saved="showForm = false" @cancel="showForm = false" />
            </b-col>
            <b-col cols="12">
              <representative-card
                v-for="representante in currentCompany.representantes_legais"
                :representativeData="representante"
                :key="representante.id"
                :editable="!showForm"
                :downloadable="
                  Boolean(representante.documento_identidade.url) &&
                  Boolean(representante.comprovante_residencia.url)
                "
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BAlert, BBadge, BCard, BCol, BRow, BButton } from "bootstrap-vue";
import AppPageHeader from "@core/components/app-page-header/AppPageHeader";
import RepresentativeCard from "@/views/client/user/components/RepresentativeCard";
import RepresentativeForm from "@/views/client/user/components/RepresentativeForm";
import CompanyDocumentCard from "@/views/admin/companies/components/CompanyDocumentCard";

export default {
  name: "CompanyUserProfile",
  components: {
    RepresentativeCard,
    RepresentativeForm,
    AppPageHeader,
    BCard,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    CompanyDocumentCard,
  },
  data() {
    return {
      title: "Minha Conta",
      showForm: false,
      representative: {
      }
    };
  },
  async mounted() {
    if (this.empresaId) {
      await this.$store.dispatch("company/fetch", this.empresaId);
    }
  },
  methods: {
    showRepresentativeForm() {
      this.showForm = true;

    }
  },
  computed: {
    empresaId() {
      return this.$store.state.auth.userData.user_type === "admin"
        ? this.$route.params.id
        : this.$store.state.auth.userData.empresa_id;
    },
    currentCompany() {
      return this.$store.state.company.current;
    },
    repproved() {
      return this.$store.state.auth.userData.empresa?.status_documentacao === "reprovado";
    },
    inAnalysis() {
      return ["pendente", "em_progresso"].includes(
        this.$store.state.auth.userData.empresa?.status_analise
      );
    },
    approved() {
      return (
        this.$store.state.auth.userData.empresa?.status_analise === "finalizada" &&
        this.$store.state.auth.userData.empresa?.status_documentacao === "aprovado"
      );
    },
  },
};
</script>
